const saladsData = [
  {
    name: 'Greek Salad',
    desc: 'Spring mix, tomatoes, cucumbers, onions, peppers, olives, feta, Greek dressing.',
    price: {
      small: 9,
      large: 16,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Fatoosh',
    desc: 'Tomatoes, cucumbers, peppers, onions, pita chips, house blend sumac, lemon, olive oil.',
    price: {
      small: 9,
      large: 16,
    },
  },
  {
    name: 'Mashgara Tabouli',
    desc: 'Finely chopped parsley, tomatoes, cucumbers, onions, mint, cracked wheat, lemon, olive oil...named after the home village in Lebanon.',
    price: {
      small: 9,
      large: 16,
    },
  },
  {
    name: 'Falafel Salad',
    desc: 'Falafel patties, served atop a diced salad of tomatoes, cucumbers, radishes, pickles, onions and tahini sauce.',
    price: {
      small: 9,
      large: 16,
    },
  },
  {
    name: 'Artichoke Salad',
    desc: 'Artichoke hearts, hearts of palm, radishes, grape tomatoes, spring mix, lemon feta vinaigrette.',
    price: {
      small: 10,
      large: 16,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Poco House Salad',
    desc: 'Spring mix, red onions, candied pecans, grape tomatoes, raspberry vinaigrette.',
    price: {
      small: 8,
      large: 14,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Roasted Beet Salad',
    desc: 'Roasted beets, peppers, red onions, goat cheese, candied pecans, spring mix, garlic balsamic vinaigrette.',
    price: {
      small: 10,
      large: 16,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
];

export default saladsData;
