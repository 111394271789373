const seafoodData = [
  {
    name: 'Shrimp Saganaki',
    desc: 'Sautéed shrimp with garlic, white wine, fresh oregano, tomato and feta.',
    price: {
      small: 9,
      large: 16,
    },
  },
  {
    name: "Fried Calamari Za'atar",
    desc: 'Breaded and dusted with za’atar, served with spicy marinara.',
    price: {
      small: 9,
      large: 16,
    },
  },
  {
    name: 'Shrimp Asti-Spumante',
    desc: 'Sautéed shrimp with garlic, Asti-Spumante and parsley.',
    price: {
      small: 9,
      large: 16,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: "Thai Shrimp 'Lasagna'",
    desc: 'Sautéed shrimp layered with crispy wontons, spicy thai and basil coconut milk.',
    price: {
      small: 9,
      large: 16,
    },
  },
  {
    name: 'Lump Blue Crab Cakes',
    desc: 'Tender lump crab cakes accompanied by a roasted red pepper cream sauce.',
    price: {
      small: 12,
      large: 22,
    },
  },
  {
    name: 'Sumac Encrusted Tuna',
    desc: 'AAA Graded tuna, encrusted with house-blend sumac spice, seared rare, sliced and served atop fresh cucumber and onion with tahini.',
    price: {
      small: 12,
      large: 22,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
];

export default seafoodData;
