const bigPlatesData = [
  {
    name: 'Poco Piatti Kabobs',
    desc: 'Pick any combination of kabobs. Served over rice pilaf. 2 for 18, 3 for 26, 4 for 35',
  },
  {
    name: 'Beef Tenderloin Shish Kabobs',
    desc: 'Beef tenderloin filet, skewered and grilled with vegetables.',
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Shish Tawook',
    desc: 'Marinated chicken breast, skewered and grilled with vegetables.',
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Shish Kafta',
    desc: 'Mixture of ground lamb, ground beef, seasoning, onion and parsley, skewered and charbroiled.',
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Vegetable Kabob',
    desc: 'Seasonal Vegetables, skewered & charbroiled',
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Grilled Baby Lamb Chops',
    desc: 'New Zealand rack lamb chops marinated in balsamic and fresh herbs and charbroiled. Served over rice pilaf. The Chef recommends medium rare temperature.',
    price: 32,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Poco Piatti Filet',
    desc: '6 to 7 oz center cut filet. Served with sautéed peppers, mushrooms, onions and rice pilaf.',
    price: 30,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  // {
  //   name: 'Lamb Shank Ossu Buco',
  //   desc: 'Poco specialty served over rice pilaf with carrots, celery and tomato sauce.',
  //   price: 25,
  //   dietary: {
  //     type: 'gluten-free',
  //     title: 'Gluten Free',
  //   },
  // },
  {
    name: 'Mousakka',
    desc: 'A layered eggplant dish of sautéed eggplant, ground beef and lamb spiced and our house-made marinara, topped with bechamel sauce and our three cheese blend.',
    price: 16,
  },
  {
    name: 'Beef Marsala',
    desc: 'Tender beef tenderloin tips with red peppers, red onion and mushrooms in a wine and cream sauce. Served over rice pilaf.',
    price: 18,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
];

export default bigPlatesData;
