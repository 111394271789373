const pizzaData = [
  {
    name: 'Caprese',
    desc: 'Fresh mozzarella, sliced tomato, fresh basil, olive oil.',
    price: 12,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your sever for gluten free crust.',
    },
  },
  {
    name: 'Chorizo and Pepperoncini ',
    desc: 'Chorizo sausage, roasted tomato sauce, hot pepper, onion, three cheese blend.',
    price: 12,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your sever for gluten free crust.',
    },
  },
  {
    name: 'Mediterranean Shrimp',
    desc: 'Roasted feta dip, sautéed shrimp, tomato, garlic, feta.',
    price: 12,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your sever for gluten free crust.',
    },
  },
  {
    name: 'The Poco Piatti ',
    desc: 'Garlic sauce, sliced tomato, roasted peppers, onion, kalamata olives, feta.',
    price: 12,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your sever for gluten free crust.',
    },
  },
  {
    name: 'Italian Sausage and Ricotta',
    desc: 'Spicy Italian sausage, roasted tomato sauce, ricotta cheese, roasted red pepper, fresh basil.',
    price: 12,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your sever for gluten free crust.',
    },
  },
  {
    name: 'Spinach Artichoke',
    desc: 'Roasted feta dip, artichoke, fresh spinach, tomato, three cheese blend.',
    price: 12,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your sever for gluten free crust.',
    },
  },
  {
    name: 'Portabella and Artichoke',
    desc: 'Goat cheese pesto, portabella mushroom, artichoke, caramelized onion, three cheese blend.',
    price: 12,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your sever for gluten free crust.',
    },
  },
];

export default pizzaData;
