const carneData = [
  {
    name: 'Poco Tips',
    desc: 'Beef tenderloin tips or chicken sautéed with lemon and garlic. Served on a bed of hummous.',
    price: {
      small: 10,
      large: 18,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Grilled Baby Lamb Chops',
    desc: 'Lamb chops marinated in balsamic and fresh herbs and charbroiled. The Chef recommends medium rare temperature.',
    price: 20,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Ara-yes Halabi',
    desc: 'Lamb, parsley and onion baked in our house-made pita. Served with hummous.',
    price: {
      small: 9,
      large: 16,
    },
  },
  {
    name: 'Kibbe Nayee (raw)',
    desc: 'Lebanese delicacy: a blend of raw meat, (beef or lamb), softened cracked wheat, onion, spices. Served with raw veggies (cucumber, onion, and radish).',
    price: {
      small: 13,
      large: 18,
    },
  },
  // {
  //   name: 'Kibbe Nayee Lamb (raw)',
  //   desc: 'Mixed with cracked wheat and spices. Served with onion, cucumbers and radish.',
  //   price: 18,
  // },
  {
    name: 'Cabbage Rolls',
    desc: 'Tender cabbage leaves stuffed with a combination of ground lamb, beef and rice, hand rolled, layered with fresh garlic, tomato sauce and mint.',
    price: {
      small: 8,
      large: 15,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  // {
  //   name: 'Eggplant Rolotini',
  //   desc: 'Baked eggplant stuffed with ricotta and salami. Topped with marinara and our three cheese blend.',
  //   price: 9,
  // },
  {
    name: 'Markouk Chicken Wraps',
    desc: 'Marinated grilled chicken thigh wrapped in thin markouk bread with garlic sauce and a pickle. Served with garlic sauce for dipping.',
    price: {
      small: 8,
      large: 15,
    },
  },
  {
    name: 'Meat Grape Leaves',
    desc: 'Hand rolled grape leaves filled with ground beef, lamb, rice and Syrian spice. Cooked with lemon juice and mint.',
    price: {
      small: 6,
      large: 11,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Chicken Parmesan Sliders',
    desc: 'Spicy chicken meatballs made with homemade seasoned bread crumbs topped with marinara on a brioche bun.',
    price: {
      small: 13,
      large: 23,
    },
  },
  {
    name: 'Lamb Sliders',
    desc: 'Grilled lamb sliders topped with a caramelized onion cream cheese and baby arugula on a brioche bun.',
    price: {
      small: 13,
      large: 23,
    },
  },
  // {
  //   name: 'Chorizo and Beans',
  //   desc: 'Chorizo with white beans in a spicy tomato sauce.',
  //   price: 8,
  //   dietary: {
  //     type: 'gluten-free',
  //     title: 'Gluten Free',
  //   },
  // },
];

export default carneData;
