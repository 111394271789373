const vegetarianData = [
  {
    name: 'Hummuos B Tahini',
    desc: 'Family recipe shared from The Beirut Restaurant.',
    price: {
      small: 7,
      large: 12,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your server for fresh veggies.',
    },
  },
  {
    name: 'Roasted Garlic Feta Dip',
    desc: 'Warm and creamy feta, cream cheese, roasted garlic and fresh herbs, topped with fresh diced tomato.',
    price: {
      small: 7,
      large: 12,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your server for fresh veggies.',
    },
  },
  {
    name: 'Lebanee',
    desc: 'Lebanese yogurt, topped with extra virgin olive oil, cucumber and mint.',
    price: {
      small: 6,
      large: 10,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free Option',
      subtitle: 'Ask your server for fresh veggies.',
    },
  },
  {
    name: 'Saganaki Kasseri',
    desc: 'Greek kasseri cheese, flamed tableside!.',
    price: 7,
  },
  {
    name: 'Sauteed Mushrooms ',
    desc: 'Button mushrooms sautéed in ghee and spices.',
    price: 10,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Mujadra',
    desc: 'Lentils and rice cooked with onions and olive oil, garnished with pickles and caramelized onions.',
    price: {
      small: 7,
      large: 13,
    },
  },
  // {
  //   name: 'Lubia Bi Zeit',
  //   desc: 'Sauteed green beans with garlic and onions in a tomato sauce.',
  //   price: 10,
  //   dietary: {
  //     type: 'gluten-free',
  //     title: 'Gluten Free',
  //   },
  // },
  {
    name: 'Veggie Grape Leaves',
    desc: 'Hand rolled grape leaves filled with rice, chickpeas, tomato, onion, lemon and spices. Served cold.',
    price: {
      small: 6,
      large: 10,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Fried Brussel Sprouts',
    desc: 'Brussel Sprouts with figs, pecans, arugula and feta topped with a balsamic glaze.',
    price: 14,
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Roasted Cauliflower',
    desc: 'Cauliflower roasted in house-made garlic oil, finished with tahini and fresh dill.',
    price: {
      small: 8,
      large: 15,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Stuffed Portabella Caps',
    desc: 'Portobello caps stuffed with a broccoli pesto and topped with three cheese blend, finished with a balsamic glaze.',
    price: {
      small: 8,
      large: 15,
    },
    dietary: {
      type: 'gluten-free',
      title: 'Gluten Free',
    },
  },
  {
    name: 'Eggplant Rolotini',
    desc: 'Fried eggplant stuffed with ricotta cheese, topped and baked with our three cheese blend and marinara.',
    price: 13,
  },
];

export default vegetarianData;
